// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_USERS':
      return { ...state, allData: action.data, total: action.totalPages }
    case 'GET_USERS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      action.selectedUser.fullName = action.selectedUser.nombre + ' ' + action.selectedUser.apellido
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state }
    case 'UPDATE_USER':
      return { ...state }
    case 'LOCK_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    case 'ADD_USER_TO_ROLE':
      return { ...state }
    case 'REMOVE_USER_FROM_ROLE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
