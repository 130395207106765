// ** Initial State
const initialState = {
    selectedPatient: {}
  }
  
  const patients = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PATIENT':
            return { ...state, selectedPatient: action.data }
        default:
            return { ...state }
    }
  }
  export default patients
  