// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedInstitucion: null,
  loading: false,
  error: null,
}

const instituciones = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_INSTITUCIONES':
      return { ...state, allData: action.data, loading: false }
    case 'GET_INSTITUCIONES':
      return {
        ...state,
        data: action.data,
        loading: false,
        total: action.totalPages,
        params: action.params,
      }
    case 'GET_INSTITUCION':
      return {
        ...state,
        loading: false,
        selectedInstitucion: action.selectedInstitucion,
      }
    case 'ADD_INSTITUCION':
      return { ...state }
    case 'UPDATE_INSTITUCION':
      return { ...state }
    case 'LOCK_INSTITUCION':
      return { ...state }
    case 'DELETE_INSTITUCION':
      return { ...state }
    case 'INSTITUCION_ACTION_SUCCESS':
      return { ...state, loading: false, error: null }
    case 'INSTITUCION_ACTION_ERROR':
      return { ...state, loading: false, error: action.error }
    case 'INSTITUCION_ACTION_LOADING':
      return { ...state, loading: true, error: null }
    default:
      return { ...state }
  }
}
export default instituciones
