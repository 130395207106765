// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import layout from './layout'
import users from '@src/views/apps/user/store/reducer'
import instituciones from '@src/views/apps/institucion/store/reducer'
import roles from '@src/views/apps/role/store/reducer'
import person from '@src/views/apps/person/store/reducer'
import patient from '@src/views/apps/patient/store/reducer'
import medicalRecord from '@src/views/apps/medicalRecord/store/reducer'

const rootReducer = combineReducers({
  auth,
  users,
  instituciones,
  roles,
  person,
  patient,
  medicalRecord,
  layout
})

export default rootReducer
