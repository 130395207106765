// ** React Imports
import React, { useState, useEffect, Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from '@components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './services/interceptors/axios'
import axios from 'axios'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { QueryClientProvider, QueryClient } from 'react-query'

// Create a client
const queryClient = new QueryClient()

const MySwal = withReactContent(Swal)

function AppWrapper() {
  const [isHealthy, setIsHealthy] = useState(null)
  const API_URL = process.env.REACT_APP_API_AUTH + 'health'

  const noConexion = (flag) => {
    if (flag) {
      MySwal.fire({
        title:
          'El servicio no se encuentra disponible por problemas de conectividad',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: 'Cerrar',
        allowEscapeKey: false
      })
    }
  }
  useEffect(() => {
    const checkHealth = async () => {
      try {
        const response = await axios.get(API_URL)
        if (response.status === 200) {
          setIsHealthy(true)
          MySwal.close()
        } else {
          setIsHealthy(false)
          noConexion(true)
        }
      } catch (error) {
        setIsHealthy(false)
        noConexion(true)
      }
    }

    // Llamar inmediatamente al cargar el componente
    checkHealth()

    // Configurar el intervalo
    const healthCheckInterval = setInterval(checkHealth, 20000)

    // Limpiar el intervalo cuando el componente se desmonte
    return () => {
      clearInterval(healthCheckInterval)
    }
  }, [])

  if (isHealthy === null) return <span>Checking conection...</span> // Puedes mostrar un spinner aquí
  return <LazyApp />
}
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <QueryClientProvider client={queryClient}>
              <AppWrapper />
            </QueryClientProvider>
            <ToastContainer newestOnTop />
          </IntlProviderWrapper>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
