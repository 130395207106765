import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CHOOSE_INSTITUCION
} from "../../actions/types";

const userData = JSON.parse(localStorage.getItem("userData"));

// if (user !== 'undefined') {
//   user = JSON.parse(localStorage.getItem("user"));
// }

const initialState = userData
  ? { isLoggedIn: true, userData, selectedInstitucion: null }
  : { isLoggedIn: false, userData: null, selectedInstitucion: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userData: payload.userData,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        userData: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userData: null,
        selectedInstitucion: null,
      };
    case CHOOSE_INSTITUCION:
      return {
        ...state,
        selectedInstitucion: payload
      };
    default:
      return state;
  }
}

// // **  Initial State
// const initialState = {
//   userData: {}
// }

// const authReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'LOGIN':
//       return {
//         ...state,
//         userData: action.data,
//         [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
//         [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
//       }
//     case 'LOGOUT':
//       const obj = { ...action }
//       delete obj.type
//       return { ...state, userData: {}, ...obj }
//     default:
//       return state
//   }
// }

// export default authReducer
