// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getToken = () => {
  return isUserLoggedIn() ? window.localStorage.getItem('token') : ''
}

export const getRefreshToken = () => {
  // return isUserLoggedIn() ? window.localStorage.getItem('refresh_token') : ''
  const user = getUserData()
  return isUserLoggedIn() ? user.refresh_token : ''
}

export const setTokens = (token, refresh) => {
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('refresh_token', refresh)
}

export const setUser = (user) => {
  window.localStorage.setItem('userData', JSON.stringify(user))
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const selectFormat = (data) => {
  return data.map((item) => {
    const obj = {
      value: item.id,
      label: item.descripcion || item.description
    }
    return obj
  })
}

export const obtenerEstadoNutricional = (imc, datos) => {
  if (imc < datos.bajoPesoSevero) {
    return 'Bajo Peso Severo'
  } else if (imc < datos.percentil3) {
    return 'Bajo Peso'
  } else if (imc < datos.percentil10) {
    return 'Riesgo de Bajo Peso'
  } else if (imc < datos.percentil85) {
    return 'Peso normal'
  } else if (imc < datos.percentil97) {
    return 'Sobrepeso'
  } else {
    return 'Obeso'
  }
}

export const obtenerTalla = (talla, datos) => {
  if (talla < datos.tallaSevera) {
    return 'Baja Talla Severa'
  } else if (talla < datos.talla) {
    return 'Baja Talla'
  } else if (talla > datos.talla) {
    return 'Talla Normal'
  }
}

export function calcularEdad(fechaNacimiento) {
  // Convertir la fecha de nacimiento a un objeto Date
  const fechaNacimientoObj = new Date(fechaNacimiento)

  // Obtener la fecha actual
  const fechaActual = new Date()

  // Calcular la diferencia en milisegundos entre la fecha actual y la fecha de nacimiento
  const edadEnMilisegundos = fechaActual - fechaNacimientoObj

  // Calcular la diferencia en meses
  const meses = Math.floor(edadEnMilisegundos / (1000 * 60 * 60 * 24 * 30.4375))

  return meses
}

export function calcularTension(tension) {
  let valorTension = 0
  if (tension > 161) valorTension = 180
  else if (tension <= 161 && tension > 141) valorTension = 160
  else if (tension <= 141 && tension > 121) valorTension = 140
  else if (tension <= 121) valorTension = 120
  else valorTension = 0

  return valorTension
}

export function getScore(scores, smoke, diabetes, tension) {
  console.log({
    scores: scores,
    smoke: smoke,
    diabetes: diabetes,
    tension: tension
  })
  const objetoEncontrado = scores.find(
    (objeto) =>
      objeto.fumador === smoke &&
      objeto.diabetes === diabetes &&
      objeto.tension === tension
  )

  if (objetoEncontrado) {
    console.log(objetoEncontrado.score)
    return objetoEncontrado.score
  }

  return 0 // Si no se encuentra ningún objeto que coincida
}

export function scoreText(score) {
  switch (score) {
    case 1:
      return score + '\u00A0 BAJO'
    case 2:
      return score + '\u00A0 MEDIO BAJO'
    case 3:
      return score + '\u00A0 MEDIO'
    case 4:
      return score + '\u00A0 MEDIO ALTO'
    case 5:
      return score + '\u00A0 ALTO'
    default:
      return 'No calculado'
  }
}
