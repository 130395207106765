// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedRole: {}
  }
  
  const roles = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_ROLES':
        return { ...state, allData: action.data }
      case 'GET_ROLES':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_ROLE':
        return { ...state, selectedRole: action.selectedRole }
      case 'ADD_ROLE':
        return { ...state }
      case 'UPDATE_ROLE':
        return { ...state }
      case 'LOCK_ROLE':
        return { ...state }
      case 'DELETE_ROLE':
        return { ...state }
      case 'GET_CLAIMS':
        return { ...state, claims: action.data }
      case 'GET_ROLE_CLAIMS':
        return { ...state, selectedRole: action.selectedRole }
      default:
        return { ...state }
    }
  }
  export default roles
  